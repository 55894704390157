<template>
  <div id="mediaAdd">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/media' }">媒体发布</el-breadcrumb-item>
			<el-breadcrumb-item>添加发布</el-breadcrumb-item>
		</el-breadcrumb>
    <el-form
      :model="mediaForm"
      ref="mediaForm"
      :rules="rules"
      label-width="140px"
      class="media-form"
      style="padding-top: 20px;box-sizing: border-box"
      >
      <el-row style="width: 90%;margin-left:5%">
        <el-col :span="12">
          <el-form-item label-width="80px" label="发布名称">
            <el-input v-model="mediaForm.name" type="text" placeholder="请输入发布名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" label="发布来源">
            <el-input v-model="mediaForm.source" type="text" placeholder="请输入发布来源"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label-width="80px" label="发布链接" class="media-form-link" prop="url">
            <el-input v-model="mediaForm.url" type="text" placeholder="请输入媒体发布链接"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item class="investSubmit">
        <el-button type="primary" @click="submitForm('mediaForm')" size="medium" class="searchBtn">发布</el-button>
        <el-button type="primary" @click="$router.push('/media')" size="medium" class="searchBtn">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { success } from "@/utils/notification";

export default {
  name: 'mediaAdd',
  data() {
    const urlTest = (rule, value, callback) => {
      let urlRegExp = /^(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$/
      if(!urlRegExp.test(value)){
        callback("链接格式错误，请填写正确的网址")
        this.mediaForm.url = ''
      }
    };
    return {
      id: Number(sessionStorage.organizationId),
      mediaForm: {
        organ_id: Number(sessionStorage.organizationId),
        type: 'media',
        name: "",
        source: '',
        url: "",
      },
      rules: {
        url: [
          { validator: urlTest, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm() {
      if(this.$route.query.id == undefined) { //添加
        this.$http.post(this.URL.adminUrl.mediaOrActivityList, this.mediaForm).then(res => {
          this.$router.push('/media')
          success ("添加成功")
        });
      }else{ //编辑
        this.$http.put(this.URL.adminUrl.mediaOrActivityList +'/'+ this.$route.query.id, this.mediaForm).then(res => {
          this.$router.push('/media')
          success ("编辑成功")
        });
      }
    }
  },
  created() {
    if(this.$route.query.id != undefined) {
      this.$http.get(this.URL.adminUrl.mediaOrActivityList +'/'+ this.$route.query.id).then(res => {
        this.mediaForm = res.data.data
      });
    }
  }
}
</script>
<style lang="less" scoped>
  #mediaAdd{
    .media-form{
      margin-top: 30px;
    }
  }
</style>
